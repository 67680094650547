import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import { Locale } from 'vant'
// 引入英文语言包

import vanEnLocale from 'vant/es/locale/lang/en-US'
import vanZhLocale from 'vant/es/locale/lang/zh-CN'
import enLocale from './en'
import zhLocale from './zh'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...vanEnLocale
  },
  zh: {
    ...zhLocale,
    ...vanZhLocale
  }
}

export function getLanguage () {
  let chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  chooseLanguage = 'zh'
  Cookies.set('language', chooseLanguage)
  return chooseLanguage
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

export function vanLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, vanEnLocale)
  } else if (lang === 'zh') {
    Locale.use(lang, vanZhLocale)
  }
}

vanLocales(getLanguage())

export default i18n
