import dayjs from 'dayjs'
import { getLanguage } from '@/lang'

/**
 * 格式化日期
 * @param {String|Date} date
 * @param {String} format
 * @returns {String}
 */
export function formatDate (date, format = 'YYYY-MM-DD') {
  if (date) {
    try {
      return dayjs(date).format(format)
    } catch (e) {
      return date
    }
  }

  return date
}

/**
 * 格式化数字
 * @param {Number|String} number
 * @param {Object} options
 * @returns {string}
 */
export function formatNumber (number, options) {
  if (!options) {
    options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
  return isNaN(Number(number)) ? number : Number(number).toLocaleString('en-US', options)
}

/**
 * 多语言描述
 * @param item
 * @param keys
 * @returns {string|*}
 */
export function getLabel (item, keys = ['name', 'name_cn']) {
  const language = getLanguage()
  if (item) {
    if (language === 'en') {
      return item[keys[0]]
    } else {
      return item[keys[1]]
    }
  }

  return ''
}
